import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartScatter } from "@fortawesome/pro-regular-svg-icons";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/card";
import AttractorGif from "../images/attractor.gif";
import LogoCloud from "../components/LogoCloud";

const RubyOnRailsLegacy = () => {
  return (
    <Layout>
      {(linkClicked, cardVisible) => (
        <>
          <SEO keywords={[]} title="Ruby on Rails Consulting and Development" />
          <Card
            visible={cardVisible}
            header="Ruby on Rails Consulting and Development"
            previousUrl="/ruby-on-rails"
            nextUrl="/ruby-on-rails-legacy-2"
          >
            <p className="text-base leading-6 text-gray-700 text-left mb-4 sm:mb-5">
              Sometimes I’m consulted with refactoring a legacy RoR application
              that I’ve never seen before. That’s when an informed use of
              certain <strong>code metrics</strong> can come in very handy:
            </p>
            <div className="grid gap-6 grid-cols-1">
              <div className="flex">
                <FontAwesomeIcon
                  icon={faChartScatter}
                  size="2x"
                  className="text-magenta-900"
                />
                <div className="ml-5">
                  <h2 className="text-left text-gray-700 leading-6 text-lg mb-4">
                    Code Complexity vs Churn:
                  </h2>
                  <p className="text-base leading-6 text-gray-700 text-left mb-3">
                    <a
                      href="https://www.agileconnection.com/article/getting-empirical-about-refactoring"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-magenta"
                    >
                      Several
                    </a>{" "}
                    <a
                      href="https://www.sandimetz.com/blog/2017/9/13/breaking-up-the-behemoth"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-magenta"
                    >
                      authors
                    </a>{" "}
                    have shown that the two-dimensional space generated by{" "}
                    <strong>churn</strong> (the frequency of change of a certain
                    file or other code entity) and <strong>complexity</strong>{" "}
                    (e.g. cyclomatic complexity or a similar metric) conveys
                    meaningful information about code quality.
                  </p>
                  <p className="text-base leading-6 text-gray-700 text-left mb-3">
                    In other words: Files that change often and contain large
                    complexity often contain the least understood code
                    sequences. Because that’s such an important metric and I
                    couldn’t find a tool that covers both Ruby and JavaScript
                    equally well, I made my own -{" "}
                    <a
                      href="https://github.com/julianrubisch/attractor"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-magenta"
                    >
                      Attractor
                    </a>
                    .
                  </p>
                  <p className="text-base leading-6 text-gray-700 text-left mb-3">
                    Using this metric I can obtain a quick glance at the pain
                    points in a codebase, and know where to look first to
                    understand the application’s tech debt issues.
                  </p>
                </div>
              </div>
              <figure className="w-full">
                <img alt="attractor" src={AttractorGif} />
              </figure>
            </div>
          </Card>
          <LogoCloud
            logos={[
              {
                name: "bullet-train",
                url: "https://bullettrain.co/",
                type: "png",
              },
              {
                name: "code-and-co",
                url: "https://www.codeandco.com/",
                type: "svg",
              },
              {
                name: "inter-pool",
                url: "https://www.inter-pool.at",
              },
              {
                name: "kreativfarm",
                url: "http://kreativ.farm/",
              },
            ]}
          />
        </>
      )}
    </Layout>
  );
};

export default RubyOnRailsLegacy;
